import httpClient from '@/shared/utils/http'
import axios from 'axios'

export default {
  fetchUser: () => httpClient.get('/users/details', { cache: true }),
  updateProfile: (data) => httpClient.patch('/users/update', data),
  updatePassword: ({ password, token }) => {
    return axios({
      method: 'PATCH',
      url: `${process.env.VUE_APP_BASE_URL}users/setPassword`,
      data: { password },
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
  },
  checkPhone: (number) => httpClient.get(`/users/checkPhone/${number}`),
  orders: (page = 1) => httpClient.get(`/invoices/?page=${page}`),
  cancelOrder: (id) => httpClient.get(`invoices/cancel/${id}`),
  fetchLocation: (city) =>
    httpClient.get(`public/localities/search/?name=${city}`),
  primaryCities: () => httpClient.get('public/city/primary'),
  searchCity: (city) => httpClient.get(`public/city/search?name=${city}`),
  allowEmail: (email) => httpClient.get(`public/city/checkMail?email=${email}`)
}
