import auth from '@/router/middleware/auth'

export default [
  {
    path: 'account',
    name: 'AccountPage',
    redirect: {
      name: 'Info'
    },
    component: () => import('../views/Page'),
    children: [
      {
        path: 'mypage',
        name: 'MyPage',
        component: () => import('@/containers/Map/views/InFuture'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'myfriends',
        name: 'MyFriends',
        component: () => import('@/containers/Map/views/InFuture'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import('../views/Info'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'settings',
        name: 'AccountSettings',
        component: () => import('../views/Settings'),
        meta: {
          middleware: [auth]
        }
      }
    ]
  }
]
