export default {
  setUser (state, user) {
    state.user = user
  },
  setUserPhones (state, data) {
    state.user.phones.push(data)
  },
  deleteUserPhone (state, idx) {
    state.user.phones = state.user.phones.filter((phone, key) => key !== idx)
  },
  setLocationItems (state, data) {
    data =
      data &&
      data.map((el) => {
        const arr = [el.name]
        if (el.regionName) {
          arr.push(el.regionName)
        }
        if (el.areaName) {
          arr.push(el.areaName)
        }
        el.name = arr.join(', ')
        return el
      })
    state.locationItems = data || []
  },
  updateUserPhoto (state, path) {
    state.user.avatar = path
  },
  resetPrivacyState (state) {
    state.user = null
    state.locationItems = []
  },
  setOrders (state, data) {
    state.orders = data
  },
  updateOrderById (state, { id, status }) {
    state.orders.invoices = state.orders.invoices.map((el) => {
      if (el.id === id) {
        el.status = status
      }
      return el
    })
  },
  setPrimaryCities (state, data) {
    state.primaryCities = data
  },
  setResultFoundCity (state, data) {
    data =
      data &&
      data.map((el) => {
        el.city = el.name
        const arr = [el.name]
        if (el.district) {
          arr.push(el.district)
        }
        if (el.region) {
          arr.push(el.region)
        }
        el.name = arr.join(', ')
        return el
      })
    state.cities = data || []
  },
  setLoginUser (state, data) {
    state.loginUser = data
  }
}
