import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: () => ({
    user: null,
    locationItems: [],
    orders: [],
    primaryCities: [],
    cities: [],
    loginUser: null
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
}
