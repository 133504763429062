import Vue from 'vue'
const domain =
  location.hostname === 'localhost' ? 'localhost' : '.e-vybir.ua'

export default {
  setAuth (state) {
    state.isAuthed = true
  },
  logOut (state) {
    state.isAuthed = false
    Vue.$cookies.remove('_token', null, domain)
    localStorage.removeItem('compareProducts')
    localStorage.removeItem('favoriteProducts')
  },
  setToken (state, token) {
    Vue.$cookies.set('_token', token, null, null, domain)
  }
}
