import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: () => ({
    isAuthed: false
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
}
