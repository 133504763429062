<template>
  <v-app>
    <header-component />
    <notifications position="bottom center" class="customize-notification" />
    <v-main class="main">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <dialog-instruction
      :dialog="getDialogInstructionState"
      @close="setDialogInstructionState(false)"
    />
    <footer-component />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DialogInstruction from '@/shared/components/dialogs/DialogInstruction'

export default {
  name: 'MainLayout',
  components: {
    DialogInstruction,
    HeaderComponent: () => import('@/shared/components/Header'),
    FooterComponent: () => import('@/shared/components/Footer')
  },
  computed: {
    ...mapGetters('auth', ['isAuthed']),
    ...mapGetters('map', ['getDialogInstructionState'])
  },
  methods: {
    ...mapActions('auth', ['checkAuth']),
    ...mapMutations('map', ['setDialogInstructionState']),
    async init () {
      if (this.isAuthed && !this.$route.query.token) {
        this.$store.dispatch('user/fetchUser')
      }
    }
  },
  watch: {
    isAuthed () {
      this.init()
    },
    '$route.query.auto_login_token': {
      handler (token) {
        if (token) {
          this.$store.commit('auth/setToken', token)
          this.$store
            .dispatch('user/fetchUser')
            .then(() => {
              this.$router.replace({
                query: {}
              })
            })
            .catch(() => {
              this.$store.commit('auth/logOut')
              this.$router.push({
                name: 'Login',
                query: { status: 'error', msg: 'Токен не э валідним' }
              })
            })
        }
      },
      immediate: true
    }
  },
  async mounted () {
    this.checkAuth()
  }
}
</script>
<style lang="scss">
.customize-notification {
  .vue-notification {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
  }
}
</style>
