import httpClient from '@/shared/utils/http'

export default {
  // logIn: (credentials) => httpClient.post('auth//login', credentials, { needAuth: false }),
  logIn: (data) => httpClient.post('auth/login', data),
  signUp: (data) => httpClient.post('auth/register', data),
  resetPassword: (data) => httpClient.post('auth/resetPassword', data),
  socAuth: (data) => httpClient.post('auth/social', data),
  allowEmail: (email) => httpClient.get(`auth/checkMail?email=${email}`)
}
