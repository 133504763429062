export default [
  {
    path: 'reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword')
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: 'register',
    name: 'Register',
    component: () => import('../views/Register')
  }
]
