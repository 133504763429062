import httpClient from '@/shared/utils/http'

export default {
  regionsList: () => httpClient.get('public/regions/'),
  region: (id) => httpClient.get(`public/regions/${id}`),
  settlements: (code) => httpClient.get(`settlement/${code}`),
  warehouses: (cityId) =>
    httpClient.get(`public/city/warehouses?cityId=${cityId}`),
  subscribe: (data) => httpClient.patch('prozorro/subscribe/', data),
  getSubscribes: () => httpClient.get('prozorro/subscribe/'),
  unsubscribe: () => httpClient.delete('prozorro/subscribe/'),
  getAuction: () => httpClient.get('prozorro/subscribe/auctions'),
  calendarAuction: () => httpClient.get('prozorro/subscribe/auctions/calendar')
}
