import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

Vue.prototype.$success = function (options) {
  this.$notify({
    type: 'success',
    ...options
  })
}

Vue.prototype.$error = function (options) {
  this.$notify({
    type: 'error',
    ...options
  })
}

Vue.prototype.$warning = function (options) {
  this.$notify({
    type: 'warn',
    ...options
  })
}

Vue.prototype.$info = function (options) {
  this.$notify({
    type: 'info',
    ...options
  })
}
