/* eslint-disable import/no-named-default */
import store from '../store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewarePipeline from './middlewarePipeline'

import MainLayout from '../layouts/MainLayout.vue'

const childrenRoutes = []
const requireModule = require.context(
  '../containers',
  true,
  /\w\/router?\/index\.js$/
)
requireModule.keys().forEach((filename) => {
  childrenRoutes.push(...requireModule(filename).default)
})

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      ...childrenRoutes,
      {
        path: '*',
        name: 'PageNotFound',
        component: () => import('../containers/PageNotFound.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
