export default {
  getRegion: (state) => state.region,
  getRegionsList: (state) => {
    let regions
    if (!state.regionsList.length && localStorage.getItem('regions')) {
      regions = JSON.parse(localStorage.getItem('regions'))
    } else {
      regions = state.regionsList
    }
    return regions
  },
  getWarehouses: (state) => state.warehouses,
  getSettlements: (state) => state.settlements,
  getSubscribes: (state) => state.subscribes,
  getAuctions: (state) => state.auctions,
  getCalendarAuctions: (state) => state.calendarAuctions,
  getDialogInstructionState: (state) => state.dialogInstructionIsVisible
}
