import api from '../api'

export default {
  async fetchRegionsList ({ commit, getters }) {
    const regions = getters.getRegionsList
    if (!regions.length) {
      const response = await api.regionsList()
      commit('setRegionsList', response)
      return response
    }
  },

  async fetchRegion ({ commit }, id) {
    const response = await api.region(id)
    commit('setRegion', response)
    return response
  },

  async fetchWarehousesByCityId ({ commit }, id) {
    const response = await api.warehouses(id)
    commit('setWarehouses', response)
    return response
  },

  async fetchSettlements ({ commit }, code) {
    const response = await api.settlements(code)
    commit('setSettlements', response)
    return response
  },

  async subscribeOnLands (context, data) {
    return await api.subscribe(data)
  },

  async fetchSubscribes ({ commit }, data) {
    const response = await api.getSubscribes(data)
    commit('setSubscribes', response || [])
    return response
  },

  async removeSubscribes ({ commit }) {
    const response = await api.unsubscribe()
    commit('setSubscribes', response)
    return response
  },

  async fetchAuctions ({ commit }) {
    const response = await api.getAuction()
    commit('setAuctions', response)
    return response
  },

  async fetchCalendarAuctions ({ commit }) {
    const response = await api.calendarAuction()
    commit('setCalendarAuctions', response)
    return response
  }
}
