/**
 * Automatically imports all the modules and exports as a single module object
 */

const modules = {}
const requireModule = require.context(
  '../../containers',
  true,
  /\w\/store?\/index\.js$/
)
requireModule.keys().forEach((filename) => {
  const moduleName = filename
    .replace(/(\.\/|\/store\/index.js)/g, '')
    .toLowerCase()
  modules[moduleName] = {
    ...(requireModule(filename).default || [...requireModule(filename)]),
    namespaced: true
  }
})

export default modules
