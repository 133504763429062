import Vue from 'vue'

export default function auth ({ to, next }) {
  const token = Vue.$cookies.get('_token')
  if (!token) {
    return next({
      name: 'Login',
      query: {
        redirect: to.fullPath
      }
    })
  }
  return next()
}
