import axios from 'axios'
import Vue from 'vue'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json'
  }
})

const getAuthToken = () => Vue.$cookies.get('_token')

// interceptor to catch errors
const errorInterceptor = (error) => {
  if (error.response.status) {
    // all the error responses
    const { baseURL, url } = error.response.config
    const source = baseURL + url
    switch (error.response.status) {
      case 401: // authentication error, logout the user
        Vue.$cookies.remove('_token')
        // router.push('/auth');
        break
      case 500:
        Vue.notify({
          type: 'error',
          text: '<span class="d-flex align-center">Нажаль сервіс тимчасово не працює. Ми працюємо над цим.<i class="mdi mdi-alert font-16 ml-4"></i></span>'
        })
        break
      case 405:
        Vue.notify({
          type: 'error',
          text: `<span class="d-flex align-center">Сталася помилка при отриманні даних з ресурсу <br>${source}<i class="mdi mdi-alert font-16 ml-4"></i></span>`,
          duration: 4000
        })
        break
      default:
        console.error('Server Error', error.response.status, error.message) // eslint-disable-line
        // return Promise.reject((error.response.data && error.response.data.error) ? error.response.data.error : error.response.data)
        return Promise.reject(error.response.data)
    }
  }
  return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break
    // any other cases
    default:
    // default case
  }
  return response.data
}

// Auth Request Interceptor
const authInterceptor = (config) => {
  if (getAuthToken()) {
    config.headers.Authorization = `Bearer ${getAuthToken()}`
  }

  return config
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)
httpClient.interceptors.request.use(authInterceptor)

export default httpClient
