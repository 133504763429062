import Vue from 'vue'

Vue.filter('date', (value) => {
  if (!value) {
    return ''
  }
  return new Date(value).toLocaleDateString('uk', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
})

Vue.filter('time', (value) => {
  if (!value) {
    return ''
  }
  const date = new Date(value)
  return date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit'
  })
})
