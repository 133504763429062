import auth from '@/router/middleware/auth'

export default [
  {
    path: '',
    name: 'Land',
    component: () =>
      import(
        /* webpackChunkName: "map" */
        /* webpackPrefetch: true */ '../views/CustomizeMap'
        )
  },
  {
    path: 'subscribes',
    name: 'Subscribes',
    component: () => import('../views/Subscribes'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: 'auction',
    name: 'Auction',
    component: () => import('../views/Auction'),
    redirect: {
      name: 'Calendar'
    },
    meta: {
      middleware: [auth]
    },
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('@/containers/Map/components/Calendar')
      },
      {
        path: 'tree',
        name: 'Tree',
        component: () => import('@/containers/Map/components/Tree')
      }
    ]
  },
  {
    path: 'statistic-land',
    name: 'StatisticLand',
    component: () => import('../views/InFuture')
  }
]
