<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'є-Вибір',
    titleTemplate: '%s',
    htmlAttrs: { lang: 'ua', id: 'body' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property: 'og:url', content: location.origin },
      {
        property: 'og:image',
        content: location.origin + '/img/icons/android-chrome-192x192.png'
      }
    ]
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
