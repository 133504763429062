import api from '../api'

export default {
  async fetchUser ({ commit }) {
    const response = await api.fetchUser()
    commit('setUser', response)
    return response
  },

  async updateUserProfile ({ commit }, data) {
    data.phones = data.phones.map((element) => {
      return { number: element.number.replace(/\D/g, '').slice(2) }
    })
    const response = await api.updateProfile(data)
    commit('setUser', data)
    return response
  },
  async updateUserPassword ({ commit }, data) {
    const response = await api.updatePassword(data)
    commit('setUser', data)
    return response
  },
  async checkUserPhone (context, number) {
    return await api.checkPhone(number)
  },
  async checkEmail (context, email) {
    return await api.allowEmail(email)
  },
  async findLocation ({ commit }, city) {
    const response = await api.fetchLocation(city)
    commit('setLocationItems', response)
    return response
  },
  async fetchOrders ({ commit }, page) {
    const response = await api.orders(page)
    commit('setOrders', response)
    return response
  },
  async cancelOrder ({ commit }, id) {
    const response = await api.cancelOrder(id)
    commit('updateOrderById', {
      id,
      status: { color: 'red', name: 'Скасований' }
    })
    return response
  },
  async fetchPrimaryCities ({ commit }) {
    const response = await api.primaryCities()
    commit('setPrimaryCities', response)
    return response
  },
  async fetchCities ({ commit }, city) {
    const response = await api.searchCity(city)
    commit('setResultFoundCity', response)
    return response
  }
}
