import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: () => ({
    region: null,
    regionsList: [],
    warehouses: [],
    settlements: [],
    subscribes: [],
    auctions: [],
    calendarAuctions: [],
    dialogInstructionIsVisible: false
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
}
