export default {
  setRegionsList (state, list) {
    // console.log(list[0]); //eslint-disable-line
    state.regionsList = list
    localStorage.setItem('regions', JSON.stringify(state.regionsList))
  },
  setRegion (state, data) {
    state.region = data
  },
  setWarehouses (state, data = []) {
    state.warehouses = data
  },
  setSettlements (state, data = []) {
    state.settlements = data
  },
  setSubscribes (state, data = []) {
    state.subscribes = data
  },
  pushToSubscribes (state, data = []) {
    state.subscribes.push(data)
  },
  resetPrivacyState (state) {
    state.subscribes = []
  },
  setAuctions (state, data = []) {
    state.auctions = data
  },
  setCalendarAuctions (state, data = []) {
    state.calendarAuctions = data
  },
  setDialogInstructionState (state, value) {
    state.dialogInstructionIsVisible = value
  }
}
