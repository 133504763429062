import api from '../api'
import Vue from 'vue'

export default {
  async logIn ({ commit }, data) {
    data.email = data.email.toLowerCase().trim()
    Vue.$cookies.set('_email', data.email)
    const response = await api.logIn(data)
    commit('setToken', response.token)
    commit('setAuth')
  },
  async signUp (context, data) {
    const response = await api.signUp(data)
    data.email = data.email.toLowerCase().trim()
    Vue.$cookies.set('_email', data.email)
    return response
  },
  async checkAuth ({ commit }) {
    const token = Vue.$cookies.get('_token')
    if (token) {
      commit('setAuth')
    }
  },
  async resetPassword (context, obj) {
    return await api.resetPassword(obj)
  },
  async socAuth ({ commit }, obj) {
    const response = await api.socAuth(obj)
    const { token } = response
    if (token) {
      commit('setToken', token)
      commit('setAuth')
    }
    return response
  },
  async checkEmail ({ commit }, email) {
    if (email) {
      return await api.allowEmail(email)
    }
  }
}
