<template>
  <v-row justify="center">
    <v-dialog v-model="open" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card>
        <v-card-title>
          <div class="d-flex flex-column align-center w-100">
            <span class="text-h5"
            >Вітаємо на сайті першого українського архіварію ринку
              землі!</span
            >
            <small class="text--secondary"
            >(Користування цим ресурсом є і завжди буде безкоштовним)</small
            >
          </div>
          <v-btn class="close-modal" @click="close" icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn
          >
        </v-card-title>
        <v-card-text>
          <ul>
            <li>
              <span class="text-decoration-underline"
              >* Як саме користуватись цим ресурсом, які він надає
                переваги?</span>
            </li>
            <li>
              <span class="text-decoration-underline"
              >* Як отримати виплату від є-Вибір?</span>
            </li>
          </ul>
          <div>
            <div class="my-4">
              <b class="font-16"
              >Як саме користуватись цим ресурсом, які він надає переваги?</b
              >
              <br/>
              <span>
                Ми допоможемо Вам підписатися на оновлення з виставлених на
                Прозорро земельних ділянок, а також надамо зручну статистику за
                проданими або орендованими ділянками згідно з заданими Вами
                критеріями.</span
              >
            </div>
            <b class="font-16">Як це було до нас:</b>
            <br/>
            <span>
              В Україні затверджено майже 1500 ТГ, 459 міст, 886 селищ міського
              типу, 27163 села та 1323 селища. До нас вже існували деякі
              ресурси, що дозволяли користувачам отримувати оновлення в межах
              однієї області, і це було незручно тому що користувач цікавився,
              наприклад, одним певним типом земельних ділянок у певній ТГ або у
              певному місті/селищі, а жодна інша платформа не забезпечувала
              можливість налаштувати глибоку детальну фільтрацію. Унаслідок
              цього ресурси, до яких звернувся користувач, "засипали" його
              зайвою інформацією про сотні ділянок зі всіх існуючих ТГ обраної
              області. Вже на другий тиждень користувач, втомившись від
              безперервного потоку непотрібної інформації, відписувався від
              розсилки, або, на жаль, навіть оминав потрібні ділянки, що
              "сховались" серед навали стомлюючої інформації, яку ще треба
              "переварити".
            </span>
            <p class="mt-4">І ми це змінили.</p>
            <b class="font-16">Які переваги надає наша платформа:</b>
            <ol class="my-4">
              <li>
                Користування цією платформою та усіма її функціями є і завжди буде безплатним. Кінцевою
                метою роботи цього сервісу є вдосконалення процесу пошуку земельних ділянок, популяризація
                аукціонів, що реалізує система Прозорро.Продажі в цілому, та збільшення кількості успішних
                електронних земельних торгів. Саме тому "є-Вибір" пропонує виплати за умови здійснення
                Особливих Умов (п. 4.1.5, п. 4.1.6)
                <a
                @click="showDetails"
                class="text-decoration-underline"
              >Публічного Договору.</a
              >
              </li>
              <li>
                Наш бот забезпечує можливість на зручній і актуальній карті
                зробити тонке налаштування оповіщень за земельними ділянками не
                тільки в межах всієї області, а також по ТГ та навіть по містам
                і селищам
              </li>
              <li>
                Ви можете відфільтрувати ділянки згідно з цільовими
                призначеннями та класифікацією
              </li>
              <li>
                Для вашої зручності аукціони стосовно всіх ділянок, що
                відповідають критеріям Ваших налаштувань, заносяться до зручного
                календаря і зберігаються у Вашому особистому кабінеті
              </li>
              <li>
                Ви можете корегувати обрані ТГ або міста у форматі карти або у
                зручному текстовому форматі на сторінці "Налаштування"
              </li>
              <li>
                Ви можете отримати безплатну консультацію стосовно користування
                ботом або стосовно оренди, купівлі чи продажу земельних ділянок
                на Прозорро;
              </li>
              <li>
                Ми надамо Вам необхідні консультації з приводу виставлення ваших
                земельних ділянок або іншого майна на електронні аукціони
                Прозорро.
              </li>
            </ol>
            <div class="my-4">
              Наш бот не частіше ніж раз на день буде нагадувати Вам про себе
              електронним листом якщо на Прозорро будуть зареєстровані нові
              аукціони з земельними ділянками, характеристики яких підпадають
              під обрані Вами критерії. Пізніше, ми будемо надавати Вам зручну
              та інтуїтивно-зрозумілу статистику з завершених аукціонів по землі
              у ТГ та містах, що Вас цікавлять.
            </div>
            <br/>
            <b class="font-16"
            >Підтримка українських виробників:</b
            >
            <br/>
            <div class="my-4">
              Соціальний Проєкт "є-Вибір" був створений з метою підтримувати
              українських виробників, поширювати в Україні та за її межами
              інформацію про українські ноу-хау, хенд-мейди та продукцію, що
              виробляється в Україні малим та середнім бізнесом для широкого
              попиту. Ми будуємо першу в Україні безкоштовну* онлайн-платформу з
              продажу української продукції, послуг, доставки їжі, бронювання
              готелів, продажу речей б/в тощо. Наразі ми поширюємо
              <a
                href="https://www.youtube.com/c/evybir"
                target="_blank"
                class="text-decoration-underline"
              >тематичні відео щодо необхідності підтримувати українську
                продукцію</a
              >, проводимо у соціальних мережах розіграші Української продукції
              (гів-евей).
            </div>
            <p>
              Ми розуміємо потреби українського бізнесу, тому пропонуємо допомогу в питаннях економіки,
              маркетингу, юриспруденції та онлайн-послуг. Окрім того, нами було прийняте сміливе рішення
              використовувати рекламний бюджет нестандартним методом, а саме здійснювати
              <a @click="showDetails" class="text-decoration-underline">виплати за поширення інформації про нашу діяльність</a>
              українському бізнесу та бізнесменам, в тому числі
              аграріям, що мають на меті інвестувати у розвиток своєї справи шляхом участі в аукціонах
              Прозорро з таких процедур, як оренда та купівля земельних ділянок, приватизація або оренда
              державного та комунального майна.
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="800px"
      v-model="detailDialog"
      @keydown.esc="closeDetailDialog"
    >
      <iframe style="width: 100%;height: 800px; border: none;" src="https://land.e-vybir.ua/files/public-contract.pdf"/>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'DialogInstruction',
  props: ['dialog'],
  data: () => ({
    open: false,
    detailDialog: false
  }),
  watch: {
    dialog (val) {
      this.open = val
    },
    open (val) {
      if (!val) {
        this.close()
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    showDetails () {
      this.detailDialog = true
    },
    closeDetailDialog () {
      this.detailDialog = false
    }
  }
}
</script>
