// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VCol, VRow } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow
  }
})

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00c453',
        accent: '#5b009c',
        success: '#00c453',
        text: '#333333'
      }
    }
  }
})
